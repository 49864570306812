<template lang="pug">
.reservation(
  @dragstart="onDragstart($event)"
  @dragend="onDragEnd"
  @dragover="onDragover"
  @dragleave="onDragLeave"
  @drop="onDrop($event)"
  :class="{ 'reservation_dragstart': dragstart, 'reservation_dragging': itemDragging}"
)
  .d-flex
    v-icon(
      :color="draggingColor"
    ) mdi-dots-vertical
    .reservation__dragging(:class="{'reservation__dragging_added-manually': markAsAddedManually}")
      .position {{ index + 1}}

  .reservation__info(:class="{'reservation__info_dragover': dragover}")
    course-choose-provider(:item="item" @input:course="updateCourse" :key="getCourseCityID")
      template(v-slot:default="courseChoose")
        CMServiceProvider
          exam-autoselect(
            :examID="item.exam.ID"
            v-slot="autoselectExam"
            @forceAutoselect="fillExam"
          )
            examChooseProvider(
              :courseID="item.course.ID"
              :key="item.course.ID"
              :activeExam="item.exam"
              :candidateLicenseTypes="licenseTypes"
              ref='courseChoose'
              @loaded="autoselectExam.force"
              v-slot="exam"
            )
              exam-autoselect(
                :list="exam.list"
                :examID="item.exam.ID"
                v-slot="autoselect"
                @autoselect="checkIfNeedExamToAutofill(exam.list, $event)"
                @forceAutoselect="fillExam"
              )
                .d-flex
                  div.reservation__form
                    .wrapper.wrapper_location(
                      :class="{'wrapper_full-field': !showCommentFields}"
                    )
                      filter-autocomplete(
                        :value="getCourseCityID"
                        @input="courseChoose.loadCoursesDatesByLocation"
                        :getItems="getCourseLocation"
                        item-text="name"
                        item-value="ID"
                        label="Course Location"
                        :error="formErrors.hasError('requests', {nested: 'courseLocation', index: index})"
                        :error-messages="formErrors.getError('requests', {nested: 'courseLocation', index: index})"
                        :disabled="disabled"
                      ).reservation__location_2

                    .wrapper.wrapper_date(
                      :class="{'wrapper_full-field': !showCommentFields}"
                    )
                      v-autocomplete(
                        :value="item.course.ID"
                        @input="clearExam"
                        @change="courseDateChange"
                        label="Course"
                        :key="item.course.ID"
                        :loading="courseChoose.loading"
                        :readonly="disabled"
                        :disabled="!courseChoose.courses.length"
                        :items="courseChoose.courses"
                        item-text="dateAndName"
                        item-value="ID"
                        return-object
                        :error="formErrors.hasError('requests', {nested: 'courseDate', index: index})"
                        :error-messages="formErrors.getError('requests', {nested: 'courseDate', index: index})"
                      ).reservation__date_2
                    .wrapper.wrapper_comment(v-if="showCommentFields")
                      v-text-field(
                        label="Course comment"
                        :value="item.comment"
                        :readonly="disabled"
                        :error="formErrors.hasError('requests', {nested: 'examComment', index: index})"
                        :error-messages="formErrors.getError('requests', {nested: 'examComment', index: index})"
                        @input="updateVal($event, 'comment')"
                      )
                    .wrapper.wrapper_exam
                      span.d-flex.align-center
                        v-select(
                          v-model="item.exam.ID"
                          :items="exam.list"
                          :loading="exam.loading"
                          :readonly="disabled"
                          :disabled="!exam.hasCourse"
                          @change="fillExam(exam.list, $event)"
                          item-text="text"
                          item-value="ID"
                          label="Default exam datetime"
                          :background-color="autoselect.hasMultipleExams ? 'warning' : ''"
                          :error="formErrors.hasError('requests', {nested: 'exam', index: index})"
                          :error-messages="formErrors.getError('requests', {nested: 'exam', index: index})"
                        )

                    .wrapper.wrapper_exam-location
                      filter-autocomplete(
                        v-model="item.location"
                        :getItems="getLocations"
                        :disabled="disabled"
                        label="Exam Location"
                        testName="reservation-exam-location"
                        :error="formErrors.hasError('requests', {nested: 'location', index: index})"
                        :error-messages="formErrors.getError('requests', {nested: 'location', index: index})"
                      )

                    .wrapper.wrapper_exam-date
                      fieldDatepicker(
                        v-model="item.examDate"
                        @input="startEditing"
                        label="Exam date"
                        data-test="reservation-exam-date"
                        :error="formErrors.hasError('requests', {nested: 'examDate', index: index})"
                        :error-messages="formErrors.getError('requests', {nested: 'examDate', index: index})"
                      )

                    .wrapper.wrapper_time
                      timepicker(
                        v-model="item.timeFrom"
                        label="Time from"
                        data-test="reservation-time-from"
                        :hide-details="false"
                        :disabled="disabled"
                        :error="formErrors.hasError('requests', {nested: 'timeFrom', index: index})"
                        :error-messages="formErrors.getError('requests', {nested: 'timeFrom', index: index})"
                      )

                    .wrapper.wrapper_time
                      timepicker(
                        v-model="item.timeTo"
                        label="Time to"
                        data-test="reservation-time-to"
                        :hide-details="false"
                        :disabled="disabled"
                        :error="formErrors.hasError('requests', {nested: 'timeTo', index: index})"
                        :error-messages="formErrors.getError('requests', {nested: 'timeTo', index: index})"
                      )

                    .wrapper.wrapper_comment(v-if="showCommentFields")
                      v-text-field(
                        label="Exam comment"
                        :value="item.examComment"
                        :readonly="disabled"
                        :error="formErrors.hasError('requests', {nested: 'examComment', index: index})"
                        :error-messages="formErrors.getError('requests', {nested: 'examComment', index: index})"
                        @input="updateVal($event, 'examComment')"
                      )

                    slot(name="form")
                  .reservation__actions
                    .d-flex.align-center.justify-center.fill-height(@click="_deleteBtnClicked")
                      v-btn(icon :disabled="disabled")
                        v-icon mdi-delete
  .reservation__slots
    div
      .d-flex.justify-center(v-if="onHoldShowing")
        hold-switcher(
            small
            color="success"
            :readonly="disabled"
            :input-value="!item.onHold"
            hide-details
            :false-value="item.onHold"
            @change="setOnHold"
        )
      .reservation-slot(v-if="avaliableSlots")
        p.reservation-slot__title Avaliable slots
        .reservation-slot__main
          span.reservation-slot__count {{ item.slotsCount }}
</template>

<script>
import { DATE_INDIAN } from "@/util/validation-rules";
import CMExamConfig from '../../course_managment/classes/CMExamConfig';
import { convertToDefaultDateFormat } from '@/util';

export default {
  name: 'candidateItemReservation',
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      required: true,
      type: Number
    },
    formErrors: Object,
    getLocations: Function,
    getCourseLocation: Function,
    dragstart: Boolean,
    dontMarkManually: Boolean,
    avaliableSlots: {
      default: true,
      type: Boolean
    },
    onHoldShowing: {
      default: true,
      type: Boolean
    },
    showCommentFields: {
      default: true,
      type: Boolean
    },
    disabled: Boolean,
    licenseTypes: Array
  },

  computed: {
    draggingColor() {
      const reservationColor = this.markAsAddedManually ? 'error' : 'primary'
      return this.itemDragging ? reservationColor : ''
    },
    markAsAddedManually() {
      if (!this.item && !this.item.addedManually) return false
      return !this.dontMarkManually && this.item.addedManually;
    },
    getCourseCityID() {
      return this.item.course.city
    }
  },

  data: () => ({
    RULES: {
      DATE_INDIAN
    },
    dragover: false,
    itemDragging: false,
    forceAutocomplete: false
  }),

  methods: {
    _deleteBtnClicked() {
      this.$emit('deleteBtnClicked', this.index)
    },

    _getErrorMessage(field) {
      return this.formErrors.fields[field]
    },

    dragItem(event, data) {
      const transferedData = JSON.stringify(data)
      event.dataTransfer.setData("item", transferedData);
    },

    onDragstart(e) {
      if(this.disabled) return
      this.itemDragging = true
      this.$emit('dragstart', e)
    },

    onDragover(e) {
      e.preventDefault()
      this.dragover = true
    },

    onDragLeave() {
      this.dragover = false
    },

    onDrop(e) {
      this.itemDragging = false
      this.dragover = false
      this.$emit('drop', e)
    },

    onDragEnd(e) {
      this.itemDragging = false
      this.$emit('dragend', e)
    },

    updateVal(val, field) {
      this.item[field] = val
      this.startEditing()
    },

    informAboutChange(field) {
      this.$emit(`change:${field}`)
      this.startEditing()
    },

    setCourseDateBy(examDate) {
      if(!examDate) return
      this.updateVal(examDate, 'courseDate')
    },

    clearExam() {
      this.item.exam = new CMExamConfig()
    },

    startEditing() {
      this.$emit('startEditing')
    },

    updateCourse(course) {
      this.item.course = course
      this.startEditing()
    },

    setOnHold(v) {
      this.item.onHold = !v
      this.$emit('startEditing')
    },

    courseDateChange(course) {
      this.updateCourse(course)
      this.startEditing()
      this.setAutocompleteForce()
    },

    setAutocompleteForce() {
      this.forceAutocomplete = true
    },

    checkIfNeedExamToAutofill(exams, examID) {
      this.item.exam.ID = examID
      if(this.forceAutocomplete) {
        this.fillExam(exams, examID)
        this.forceAutocomplete = false
      }
      if (this.item.examDate || this.item.timeFrom || this.item.timeTo || this.item.location) return
      this.fillExam(exams, examID)
    },

    fillExam(exams, examID) {
      let selectedExam = exams.find(exam => exam.ID === examID)
      if (!selectedExam) return

      let examDate = convertToDefaultDateFormat(selectedExam.date)
      if (selectedExam.dateFrom && selectedExam.dateTo) examDate = convertToDefaultDateFormat(selectedExam.dateFrom)
      this.item.examDate = examDate

      this.item.timeFrom = selectedExam.timeFrom
      this.item.timeTo = selectedExam.timeTo
      this.item.location = selectedExam.location
    }
  },

  components: {
    filterAutocomplete: () => import('@/components/global/FilterAutocomplete.vue'),
    CourseChooseProvider: () => import('./reservation/CandidateCourseChooseProvider.vue'),
    holdSwitcher: () => import('@/components/global/Switcher.vue'),
    examChooseProvider: () => import('./reservation/CandidateExamChooseProvider.vue'),
    modalBody: () => import('@/app/admin/modules/course_managment/components/cm_exam/CMExamModalBody.vue'),
    examAutoselect: () => import('@/app/admin/modules/course_managment/components/cm_exam/CMExamAutoselect.vue'),
    CMServiceProvider: () => import('@/app/admin/modules/course_managment/components/CMServiceProvider.vue'),
    timepicker: () => import('@/components/global/Timepicker.vue'),
    fieldDatepicker: () => import('@/components/global/FieldDatepicker.vue')
  }
}
</script>

<style lang="scss" scoped>
.reservation {
  margin-top: 24px;
  border-radius: 8px;
  &_dragstart {
    filter:grayscale(100%)
  }
  &_dragging {
    filter: grayscale(0)
  }
  display: flex;
  &__dragging {
    display: flex;
    align-items: center;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 0 8px;
    background-color: $primary-color;
    &_added-manually {
      background-color: $error-color;
    }
  }
  &__info {
    border: 1px solid $border-color;
    border-left: unset;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    transition: background-color .2s ease;
    &_dragover {
      background-color: $default-bg-color;
    }
  }
  &__slots {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 14px;
  }
  &__form {
    display: flex;
    flex-wrap: wrap;
  }
  &__actions {
    min-width: 20px;
    padding: 0 8px;
  }
}
.wrapper {
  padding: 0 15px;
  &_date {
    width: 35%;
  }
  &_location {
    width: 35%;
  }
  &_time {
    width: 25%;
    min-width: 130px;
  }

  &_comment {
    width: 30%;
  }
  &_exam {
    width: 100%;
    &-location, &-date {
      width: 50%;
    }
  }
  &_exam::v-deep .v-select__selection--comma {
    white-space: initial;
  }
  &_full-field {
    width: 50%;
  }
}

.position {
  color: #fff;
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  border-radius: 50%;
}

.reservation-slot {
  text-align: center;
  &__title {
    font-size: 10px;
    color: $label-color;
    white-space: nowrap;
    margin-bottom: 0;
    font-weight: 500;
  }
  &__main {
    margin-top: 5px;
    width: 23px;
    height: 23px;
    border: 2px solid $border-secondary;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

  }
  &__count {
    font-weight: 500;
    color: $border-secondary;
  }
}
</style>
